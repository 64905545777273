<template>
    <div>
        <sidebar></sidebar>
        <div class="main-content">
            <router-view />
        </div>
    </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Preview from "@/components/Preview";
import { mapActions } from "vuex";

export default {
    components: {
        Sidebar,
        Preview
    },
    mounted() { },
    data() {
        return {
            loaded: false,
            company: {},
        }
    },
    computed: {
       
    },
    created() {

    },

    methods: {
        
    }
};
</script>

<style lang="scss" scoped>

</style>